/* eslint-disable import/no-unresolved */
import Vue from 'vue';
import Vuex from 'vuex';

import booking from './booking.module';
import property from './property.module';
import translation from './translation.module';

Vue.use(Vuex);

const modules = {
  booking,
  property,
  translation,
};

const store = new Vuex.Store({
  strict: process.env.NODE_ENV !== 'production',
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules,
});

export default store;
