import ApiService from "@/services";

const state = {
  propertyData: null,
  properties: [],
  fetching: false,
  fetchingAvailability: false,
};

const getters = {};

const mutations = {
  setProperties(state, data) {
    state.properties = data;
    state.fetching = false;
  },
  setProperty(state, data) {
    state.propertyData = data;
    state.fetching = false;
  },
  setFetching(state, data) {
    state.fetching = data;
  },
  setFetchingAvailability(state, data) {
    state.fetchingAvailability = data;
  }
};

const actions = {
  async searchProperties({ commit }, params) {
    commit("setFetching", true);
    const response = await ApiService.get(`properties/search`, { params });
    commit("setProperties", response.data.data);
  },
  async countProperties() {
    return await ApiService.get('properties/count');
  },
  async findEhotelAvailability({ commit }, payload) {
    commit("setFetchingAvailability", true);
    const data = await ApiService.get('properties/ehotel/availability', { params: payload });
    commit("setFetchingAvailability", false);
    return data;
  },
  async findCultswitchAvailability({ commit }, payload) {
    commit("setFetchingAvailability", true);
    const data = await ApiService.get('properties/cultswitch/availability', { params: payload });
    commit("setFetchingAvailability", false);
    return data;
  },
  async getPropertyById({ commit }, id) {
    commit("setFetching", true);
    const response = await ApiService.get(`properties/${id}`);
    commit("setProperty", response.data.data);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
