import Vue from 'vue'
import i18n from './i18n';

import '@babel/polyfill'
import 'mutationobserver-shim'

import './plugins/bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-tel-input/dist/vue-tel-input.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

import App from './App.vue'
import store from './store';
import router from './routes';
import VueTelInput from 'vue-tel-input';
import { ToggleButton } from 'vue-js-toggle-button';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: messages[rule]
  });
});

extend('accepted', {
  validate: value => value === true,
  message: 'This field must be accepted.',
});

Vue.use(VueTelInput);
Vue.component('ToggleButton', ToggleButton)
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app')
