import ApiService from "@/services";

/* eslint-disable no-shadow */
const state = {
  translation: null,
  translationKey: null,
  loading: false,
  error: null,
  // translatedPages: null,
  translatedLanguages: null,
  updateError: null,
  language: null,
  translationPending: true,
  translationLanguageiso: null,
};

const getters = {
  getTranslation: (state) => state.translation,
  loaded: (state) => !state.loading && state.translation != null,
  // translatedPages: (state) => state.translatedPages,
  translatedLanguages: (state) => (state.translatedLanguages != null ? state.translatedLanguages : []),
  getLanguages: (state) => (state.language),
  translationLanguageiso: (state) => (state.translationLanguageiso),
};

const mutations = {
  clearErrors(state) {
    state.error = null;
    state.updateError = null;
  },
  getTranslation(state, data) {
    // state.translatedLanguages = data.iso;
    state.translationLanguageiso = data.iso;
    /* TODO temp solution with hardcoded iso, because we don't have translations for all languages */
    state.translatedLanguages = ['en', 'de', 'pt', 'tr', 'ar', 'es', 'fr', 'it', 'nl', 'pl', 'ro', 'ru', 'sl', 'zh', 'da', 'bg'];
    state.translation = data.translations;
  },
  translateLanguage(state, data) {
    state.language = data;
    state.translationPending = false;
  },
  beforeTranslation(state) {
    state.translationPending = true;
  },
  afterTranslation(state) {
    state.translationPending = false;
  },
};

const actions = {
  async getTranslate({ commit }, payload) {
    if (payload.language) {
      commit('beforeTranslation');
    }
    const data = await ApiService.post('translations', payload);
    if (data) {
      commit('translateLanguage', data.data);
      return data.data;
    }
    if (payload.language) {
      commit('afterTranslation');
    }
    return [];
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
