import axios from "axios";
import Cookies from 'js-cookie';

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT_URL,
});

instance.interceptors.request.use(
  async (config) => {
    const token = Cookies.get("token") || null;
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    return Promise.reject(error);
  }
);

export default instance;
