<template>
  <div
    :class="{
      'transparent-header': ['how-gloveler-works'].includes($route.name),
    }"
  >
    <header id="myHeader" :class="{ 'inner-pg-header': innerHeader }">
      <b-navbar toggleable="lg">
        <b-navbar-toggle target="nav-text-collapse"
          ><i class="fa fa-bars" aria-hidden="true"></i
        ></b-navbar-toggle>

        <b-navbar-brand
          ><router-link to="/">Gloveler</router-link></b-navbar-brand
        >

        <b-collapse id="nav-text-collapse" is-nav ref="navBar">
          <b-navbar-nav @click="handleClick()">
            <b-nav-text
              ><router-link to="/listing">{{
                $t("header.propertyList")
              }}</router-link></b-nav-text
            >
            <b-nav-text
              ><a href="/how-gloveler-works">{{
                $t("header.howGlovelerWorks")
              }}</a></b-nav-text
            >
            <b-nav-text @click="openHelp"
              ><a href="#">{{ $t("header.help") }}</a></b-nav-text
            >
            <b-nav-text
              ><a href="/listing_map">{{ $t("header.map") }}</a
            ></b-nav-text>
            <b-nav-text
              ><a
                href="https://admin.roomdb.io/login"
                target="_blank"
                class="login"
                ><b-button variant="outline-primary">{{
                  $t("header.login")
                }}</b-button></a
              ></b-nav-text
            >
            <b-nav-text
              ><a
                href="https://admin.roomdb.io/register"
                target="_blank"
                class="register"
                ><b-button>{{ $t("header.register") }}</b-button></a
              ></b-nav-text
            >
            <li class="lang-selector-item">
              <b-dropdown :text="getLocale" right class="m-md-2 lang-selector">
                <b-dropdown-item
                  v-for="{ code, title } of getLocales"
                  :key="code"
                  @click="changeLanguage(code)"
                  >{{ title }}
                </b-dropdown-item>
              </b-dropdown>
            </li>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </header>
    <b-breadcrumb v-if="innerHeader">
      <b-breadcrumb-item href="/">
        {{ $t("breadcrumbs.home") }}
      </b-breadcrumb-item>
      <b-breadcrumb-item
        v-for="{ title, path } of breadcrumbs"
        :key="title"
        :href="path"
        >{{ title }}</b-breadcrumb-item
      >
    </b-breadcrumb>
    <button class="d-none" id="zammad-feedback-form">Feedback</button>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  data() {
    return {
      locale: "en",
      locales: [
        {
          code: "en",
          title: "English",
        },
        {
          code: "de",
          title: "Deutsch",
        },
        {
          code: "tr",
          title: "Türkçe",
        },
        {
          code: "pt",
          title: "Portuguese",
        },
        {
          code: "es",
          title: "Spanish",
        },
        {
          code: "fr",
          title: "French",
        },
        {
          code: "it",
          title: "Italian",
        },
        {
          code: "nl",
          title: "Dutch",
        },
        {
          code: "pl",
          title: "Polish",
        },
        {
          code: "ro",
          title: "Romanian",
        },
        {
          code: "ru",
          title: "Pyccкий",
        },
        {
          code: "zh",
          title: "Chinese",
        },
        {
          code: "sl",
          title: "Slovenski jezik, Slovenščina",
        },
        {
          code: "da",
          title: "dansk",
        },
        {
          code: "bg",
          title: "Bulgarian",
        },
      ],
      innerHeader: true,
    };
  },

  created() {
    if (this.$route.name == "landing-page") {
      this.innerHeader = false;
    }
  },

  computed: {
    getLocales() {
      const { locales } = this;
      return locales.sort((a, b) => {
        const titleA = a.title.toUpperCase();
        const titleB = b.title.toUpperCase();
        if (titleA < titleB) {
          return -1;
        }
        if (titleA > titleB) {
          return 1;
        }

        return 0;
      });
    },
    getLocale() {
      const locale = this.locales.find(({ code }) => code === this.locale);
      return locale ? locale.title : "English";
    },
    breadcrumbs() {
      let breadcrumbs = [];
      if (this.$route.name === "listing") {
        breadcrumbs.push({
          title: this.$t("breadcrumbs.listing"),
          path: "#",
        });
      }
      if (this.$route.name === "reservation-success") {
        breadcrumbs.push(
          {
            title: this.$t("breadcrumbs.listing"),
            path: "/listing",
          },
          {
            title: this.$t("breadcrumbs.reservationSuccess"),
            path: "#",
          }
        );
      }
      if (this.$route.name === "how-gloveler-works") {
        breadcrumbs.push({
          title: this.$t("breadcrumbs.howGlovelerWorks"),
          path: "#",
        });
      }
      if (this.$route.name === "listing-details") {
        breadcrumbs.push({
          title: this.$t("breadcrumbs.listingDetails"),
          path: "#",
        });
      }
      return breadcrumbs;
    },
  },

  mounted() {
    const locale = localStorage.getItem("locale");
    this.locale = locale || "en";
    // eslint-disable-next-line no-undef
    $(function () {
      // eslint-disable-next-line no-undef
      $("#zammad-feedback-form").ZammadForm({
        messageTitle: "How to Contact Gloveler",
        messageSubmit: "Send Message",
        messageThankYou:
          "Thank you for your inquiry (#%s)! We'll contact you as soon as possible.",
        modal: true,
        showTitle: true,
        attachmentSupport: true,
      });
    });
  },

  methods: {
    changeLanguage(locale) {
      localStorage.setItem("locale", locale);
      this.locale = locale;
      this.$router.go(0);
    },
    handleClick() {
      if (this.$refs.navBar.show == true) {
        this.$root.$emit("bv::toggle::collapse", "nav-text-collapse");
      }
    },
    openHelp() {
      document.getElementById("zammad-feedback-form").click();
    },
  },

  watch: {
    $route() {
      if (this.$route.name == "landing-page") {
        this.innerHeader = false;
      } else this.innerHeader = true;
      if (this.$refs.navBar.show == true) {
        this.$root.$emit("bv::toggle::collapse", "nav-text-collapse");
      }
    },
  },
};
</script>

<style>
.zammad-form h2 {
  text-transform: uppercase;
  color: #ff762b;
  font-weight: bolder;
  font-size: 27px;
}
.zammad-form button {
  width: 100%;
  background: #ff762b;
  color: white;
  padding: 10px;
}
</style>
